.apps-page {
    position: absolute;
    background-color: white;
}

.apps-hero {
    filter: blur(2px);
    box-shadow: 0px 0px 0px 2px black;
}

.apps-title {
    position: absolute;
    width: 100vw;
    top: 50px;
    color: white;
    text-align: center;
    z-index: 2;
}

.app-name-container {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.app-icon {
    color: rgb(27, 27, 27);
    font-size: 40px;
    margin-right: 10px;
}

.app-name {
    font-size: 30px;
    padding: 0px;
    margin: 0px;
}

.app-dates {
    color: gray;
    margin-left: 10px;
}

.app-demo-img {
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 0px 15px rgb(73, 73, 73);
    border-radius: 5px
}

.app-description {
    margin-top: 20px;
}

.app-par {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    font-size: 1.1rem;
}

.app-par a {
    text-decoration: underline;
}

.app-list-title {
    margin-left: 15px;
    margin-bottom: 0px;
}

.app-list {
    padding-right: 15px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .apps-title {
        top: 85px;
        font-size: 60px;
    }

    .apps-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .app-container {
        width: 45%;
    }
}


@media only screen and (min-width: 992px) {
    .apps-content {
        display: block;
    }

    .app-container {
        width: 100%;
        padding: 20px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .app-content {
        display: flex;
    }

    .app-icon, .app-name {
        font-size: 50px;
    }

    .app-demo-img {
        width: 40%;
        max-height: 200px;
    }

    .app-description {
        margin-top: 0px;
        margin-left: 40px;
    }

    .app-dark {
        color: rgb(230, 230, 230);
        background: linear-gradient(135deg, rgb(44, 41, 85), rgb(17, 14, 31));
    }

    .app-dark .app-icon {
        color: white;
    }

    .app-dark .app-demo-img {
        box-shadow: 0px 0px 15px black;
    }

    .app-dark a {
        text-decoration: underline;
        color: rgb(0, 238, 255);
    }
}


@media only screen and (min-width: 1200px) {
}

@import url(https://fonts.googleapis.com/css2?family=Fugaz+One&family=Racing+Sans+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: rgb(37, 37, 37);
}

.page-title {
  font-weight: bold;
  color: white;
}

.hero-img {
  width: 100vw;
  height: 150px;
  object-fit: cover;
  top: 0px;
  z-index: -1;
}


/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .hero-img {
    height: 250px;
  }
}


@media only screen and (min-width: 992px) {
  
}


@media only screen and (min-width: 1200px) {
}

.navbar-profile-name {
    padding: 10px;
}

.nav-icon {
    margin-right: 2px;
}

.brand-link {
    color: rgb(231, 231, 231);
}

.brand-link:hover {
    color: rgb(192, 192, 192);
    text-decoration: none;
}

.brand-link, .brand-link:hover {
    transition-duration: 0.3s;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.hp-page {
    width: 100vw;
    position: absolute;
    min-height: 100vh;
}

.hp-hero {
    display: none;
}

.hp-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 50px;
}

.hp-avatar-wrapper, .hp-avatar {
    width: 150px; height: 150px;
    /* border-radius: 50%; */
}

.hp-avatar-wrapper {
    overflow: hidden;
    width: 150px; height: 150px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px rgb(0, 0, 0);
    margin-bottom: 10px;
}

.hp-avatar {
    width: 200%; height: 200%;
    object-fit: cover;
    object-position: -55px 90%;
}

.hp-identity {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hp-identity-item {
    font-size: 22px;
    color: rgb(219, 219, 219);
    text-align: center;
    margin-right: 2px;
    width: 100%;
}

.hp-tagline {
    padding: 20px;
    padding-bottom: 0px;
    text-align: center;
    color: white;
}

.hp-tech-stack {
    color: rgb(154, 255, 253);
    text-align: center;
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: pink; */
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}

.hp-tech-stack-item {
    /* background-color: red; */
    margin-bottom: 0px;
    margin-top: 0px;
    color: rgb(154, 255, 253);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.hp-tech-stack-item:hover {
    color: rgb(255, 255, 255);
    transform: scale(1.1);
    text-decoration: none;
}

.hp-link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.hp-link-btn {
    background-color: rgba(255, 255, 255, 0);
    border-style: none;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 30px;
    color: rgb(255, 255, 255);
}

.hp-link-btn:hover {
    background-color: rgb(240, 240, 240);
    color: black;
}

.hp-link-btn-text {
    margin-left: 12px;
}


/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .hp-hero {
        object-position: 50% 0%;
        display: block;
    }

    .hp-content {
        width: 600px;
        margin-top: 0px;
        position: relative;
        top: -75px;
    }

    .hp-tech-stack {
        padding-left: 100px;
        padding-right: 100px;
    }
}


@media only screen and (min-width: 992px) {
    .hp-hero {
        object-position: 50% 10%;
    }
}


@media only screen and (min-width: 1200px) {
}

.bg-grid-container {
    display: none;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .bg-main-container {
        position: relative;
        /* z-index: -1; */
    }
    
    .bg-grid-container {
        display: block;
        height: 0px;
        position: relative;
    }

    .bg-grid-screen {
        position: relative;
        top: 0px;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.815);
        /* display: none; */
    }

    .bg-grid {
        display: grid;
        height: 100vh;
        position: relative;
        top: 0px;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.527);
        grid-template-columns: repeat(10fr);
        grid-template-rows: repeat(6fr);
        grid-auto-flow: column;
        grid-column-gap: 3px;
        column-gap: 3px;
        grid-row-gap: 3px;
        row-gap: 3px;
        z-index: -4;
    }

    .item {
        /* width: 100%; */
        /* height: 100%; */
        position: relative;
        z-index: -3;
        background-color: black;
        /* background-color: pink; */
    }
    .item-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
    .item-2 {
        grid-column: 1 / 4;
        grid-row: 2 / 4;
    }
    .item-3 {
        grid-column: 1 / 3;
        grid-row: 4 / 6;
    }
    .item-4 {
        grid-column: 1 / 3;
        grid-row: 6 / 7;
    }
    .item-5 {
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    .item-6 {
        grid-column: 4 / 5;
        grid-row: 2 / 3;
    }
    .item-7 {
        grid-column: 3 / 4;
        grid-row: 4 / 6;
    }
    .item-8 {
        grid-column: 3 / 5;
        grid-row: 6 / 7;
    }
    .item-9 {
        grid-column: 5 / 8;
        grid-row: 1 / 3;
    }
    .item-10 {
        grid-column: 4 / 7;
        grid-row: 3 / 6;
    }
    .item-11 {
        grid-column: 5 / 7;
        grid-row: 6 / 7;
    }
    .item-12 {
        grid-column: 8 / 9;
        grid-row: 1 / 3;
    }
    .item-13 {
        grid-column: 7 / 9;
        grid-row: 3 / 5;
    }
    .item-14 {
        grid-column: 7 / 9;
        grid-row: 5 / 7;
    }
    .item-15 {
        grid-column: 9 / 11;
        grid-row: 1 / 2;
    }
    .item-16 {
        grid-column: 9 / 11;
        grid-row: 2 / 4;
    }
    .item-17 {
        grid-column: 9 / 11;
        grid-row: 4 / 6;
    }
    .item-18 {
        grid-column: 9 / 11;
        grid-row: 6 / 7;
    }

    /* Card flipping */
    /* flip effect from https://www.youtube.com/watch?v=OV8MVmtgmoY */
    .bg-card {
        width: 100%;
        height: 100%;
        position: absolute;
        transform-style: preserve-3d;
        transition: transform 1.5s ease;
    }
    .bg-card-flipped {
        transform: rotateY(180deg);
    }
    .bg-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        /* border-style: solid; */
        font-size: 30px;
        color: white;
        font-weight: bold;
    }
    .bg-card-front {
        -webkit-backface-visibility: hidden;
        /* backface-visibility: hidden; */
        /* background-color: rgb(117, 211, 255); */
    }
    .bg-card-back {
        -webkit-backface-visibility: hidden;
        /* backface-visibility: hidden; */
        transform: rotateY(180deg);
        /* background-color: rgb(85, 85, 85); */
    }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.hp2-page {
    width: 100vw;
    min-height: 100vh;
    position: relative;
    /* display: none; */
}

.hp2-hero {
    display: none;
}

.hp2-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 30px;
}

.hp2-avatar-wrapper, .hp2-avatar {
    width: 150px; height: 150px;
    /* border-radius: 50%; */
}

.hp2-avatar-wrapper {
    overflow: hidden;
    width: 150px; height: 150px;
    border-radius: 50%;
    box-shadow: 0px 5px 8px rgb(0, 0, 0);
    margin-bottom: 10px;
}

.hp2-avatar {
    width: 200%; height: 200%;
    object-fit: cover;
    object-position: -55px 90%;
}

.hp2-identity {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hp2-identity-item {
    font-size: 22px;
    color: rgb(226, 226, 226);
    text-align: center;
    margin-right: 2px;
    width: 100%;
}

.hp2-tagline {
    padding: 20px;
    padding-bottom: 0px;
    text-align: center;
    color: white;
}

.hp2-link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.hp2-link-btn {
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 30px;
    color: rgb(255, 255, 255);
}

.hp2-link-btn:hover {
    background-color: rgb(241, 241, 241);
    /* color: black; */
    color: rgb(0, 0, 0);
}

.hp2-link-btn-text {
    margin-left: 12px;
}


/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .hp2-page {
        height: 90vh;
        padding-top: 100px;
    }

    .hp2-content {
        width: 600px;
        margin-top: 0px;
    }
}


@media only screen and (min-width: 992px) {
    .hp2-hero {
        object-position: 50% 10%;
    }
}


@media only screen and (min-width: 1200px) {
}

.footer-container {
    /* border-top-style: solid; */
    border-width: 2px;
    border-color: rgb(221, 221, 221);
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(52, 58, 64);
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
}

.footer-text, .footer-icon, .footer-link {
    color: rgb(209, 209, 209);
}

.footer-text {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
}

.footer-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-ig-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer-icon {
    margin-right: 5px;
}

.footer-link:hover {
    color: white;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
    .footer-large-only {
        display: none;
    }

    .footer-container {
        justify-content: center;
    }
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.apps-page {
    position: absolute;
    background-color: white;
}

.apps-hero {
    filter: blur(2px);
    box-shadow: 0px 0px 0px 2px black;
}

.apps-title {
    position: absolute;
    width: 100vw;
    top: 50px;
    color: white;
    text-align: center;
    z-index: 2;
}

.app-name-container {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.app-icon {
    color: rgb(27, 27, 27);
    font-size: 40px;
    margin-right: 10px;
}

.app-name {
    font-size: 30px;
    padding: 0px;
    margin: 0px;
}

.app-dates {
    color: gray;
    margin-left: 10px;
}

.app-demo-img {
    width: 100%;
    object-fit: cover;
    box-shadow: 0px 0px 15px rgb(73, 73, 73);
    border-radius: 5px
}

.app-description {
    margin-top: 20px;
}

.app-par {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    font-size: 1.1rem;
}

.app-par a {
    text-decoration: underline;
}

.app-list-title {
    margin-left: 15px;
    margin-bottom: 0px;
}

.app-list {
    padding-right: 15px;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .apps-title {
        top: 85px;
        font-size: 60px;
    }

    .apps-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .app-container {
        width: 45%;
    }
}


@media only screen and (min-width: 992px) {
    .apps-content {
        display: block;
    }

    .app-container {
        width: 100%;
        padding: 20px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .app-content {
        display: flex;
    }

    .app-icon, .app-name {
        font-size: 50px;
    }

    .app-demo-img {
        width: 40%;
        max-height: 200px;
    }

    .app-description {
        margin-top: 0px;
        margin-left: 40px;
    }

    .app-dark {
        color: rgb(230, 230, 230);
        background: linear-gradient(135deg, rgb(44, 41, 85), rgb(17, 14, 31));
    }

    .app-dark .app-icon {
        color: white;
    }

    .app-dark .app-demo-img {
        box-shadow: 0px 0px 15px black;
    }

    .app-dark a {
        text-decoration: underline;
        color: rgb(0, 238, 255);
    }
}


@media only screen and (min-width: 1200px) {
}

.pm-container {
  display: none;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .pm-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 4%;
  }
  
  .pm-line {
    width: 80%;
    height: 6px;
    background-color: #009194;
    border-radius: 3px;
  }
  
  .pm-active-dot {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #02e8ec;
    position: absolute;
    transition: all 1s ease-in-out;
    top: -8px;
    transform: translateX(-4px);
  }
  
  .pm-text {
    position: absolute;
    bottom: 10px;
    translate: -40%;
    max-width: 100px;
    z-index: 50;
    transition: opacity 0.2s ease-in-out;
  }
  
  .pm-title {
    font-size: 0.8rem;
    color: white;
    text-align: center;
    text-shadow: 1px 1px 0px #00ffff9a;
  }
  
  .pm-date {
    font-size: 0.6rem;
    color: rgb(210, 210, 210);
    text-align: center;
    vertical-align: text-top;
  }
  
  .pm-dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #009194;
    position: absolute;
    top: -4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  /* Linked Animations */
  
  :root {
    --biggen-time: 0.5s;
    --lessen-time: 0.2s;
  }
  
  .pm-group {
    cursor: pointer;
  }
  
  .pm-group:hover .pm-text {
    animation: var(--biggen-time) ease-in-out 0s biggen forwards;
  }
  .pm-group .pm-text {
    animation: var(--lessen-time) ease-in-out 0s lessen forwards;
  }
  
  .pm-group:hover .pm-dot {
    transform: scale(1.5);
  }

  .pm-group:active .pm-text {
    opacity: 0.5;
  }
  
  @keyframes biggen {
    33% {
      transform: translate(0px, -20px) scale(1.8);
    }
    100% {
      transform: translate(0px, -15px) scale(1.6);
    }
  }
  
  @keyframes lessen {
    0% {
      transform: translate(0px, -15px) scale(1.6);
    }
  }

  @keyframes groupClick {
    0% {
      transform: translate(0px, -15px) scale(1.6);
    }
    100% {
      transform: scale(1.4) translate(0px, -5px);
    }
  }
}


@media only screen and (min-width: 1200px) {
}

.sp-container {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 100px;
  /* background-color: rgba(255, 192, 203, 0.466); */
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.sp-body {
  width: 250px;
  height: 60px;
  background-color: rgb(0, 93, 0);
  border-radius: 30px;
  box-shadow: 0px 0px 20px black;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(0, 145, 0);
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  transform: translateX(100vw);
  animation: 5s ease-in-out 1s bodySlide forwards;
}

@keyframes bodySlide {
  15% {
    transform: translateX(0vw);
  }
  80% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}

.sp-slider {
  width: 70px;
  height: 55px;
  border-radius: 30px;
  background-color: rgb(0, 206, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  animation: 2.5s ease-in-out 2s slider forwards;
}

:root {
  --slide-1: -60px;
  --slide-2: -66px;
}

@keyframes slider {
  35% {
    transform: translateX(-60px);
    transform: translateX(var(--slide-1));
  }
  40% {
    transform: translateX(-60px);
    transform: translateX(var(--slide-1));
  }
  47% {
    transform: translateX(-66px);
    transform: translateX(var(--slide-2));
  }
  54% {
    transform: translateX(-60px);
    transform: translateX(var(--slide-1));
  }
  61% {
    transform: translateX(-66px);
    transform: translateX(var(--slide-2));
  }
  68% {
    transform: translateX(-60px);
    transform: translateX(var(--slide-1));
  }
  75% {
    transform: translateX(-60px);
    transform: translateX(var(--slide-1));
  }
  100% {
    transform: translateX(0px);
  }
}

.sp-chevron {
  font-size: 25px;
  padding-bottom: 3px;
  padding-right: 3px;
}

.sp-arrows {
  position: absolute;
  left: -50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.sp-moving-chevron {
  font-size: 40px;
  font-weight: bold;
  transform: scaleY(1.5);
  color: rgb(0, 77, 0);
  padding: 0px;
  margin: 0px;
  animation: 1s linear 0s infinite arrows forwards;
}

@keyframes arrows {
  100% {
    transform: translateX(-41px) scaleY(1.5);
  }
}

.sp-text {
  color: rgb(110, 181, 110);
  right: 0px;
  width: 100%;
  vertical-align: center;
  font-size: 18px;
  font-family: 'Fugaz One', cursive;
  padding-left: 12px;
  text-shadow: 0px 0px 15px black;
  position: relative;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .sp-container {
    display: none;
  }
}


@media only screen and (min-width: 1200px) {
}

.p2-page {
  width: 100vw;
  /* height: 100vh; */
  /* background-image: linear-gradient(rgb(52, 58, 64), rgb(14, 14, 14)); */
  /* background-color: pink; */
  z-index: -10;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.p2-title {
  /* font-family: 'Fugaz One', cursive; */
  color: white;
  font-size: 50px;
  letter-spacing: 5px;
  text-shadow: 3px 3px 0px #00ffff9a;
  padding-left: 15px;
  padding-top: 10px;
  /* background-color: purple; */
}

.p2-canvas {
  width: 100vw;
  /* border-style: solid; */
  border-color: white;
  /* position: relative; */
  position: relative;
  /* background-color: yellow; */
  flex-grow: 1;
}

.p2-left-btn, .p2-right-btn {
  display: none;
}

.p2-group {
  /* height: 100%; */
  width: 100%;
  transition: opacity 1s ease-in-out;
  z-index: 2;
  position: relative;
  /* pointer-events: none; */
  /* display: flex; */
  /* flex-direction: column; */
}

.p2-group-hide {
  opacity: 0;
  top: 0px;
  z-index: 1;
  position: absolute;
}

.p2-col1 {
  width: 100%;
}

.p2-text {
  margin-bottom: 15px;
  padding-left: 15px;
}

.p2-subtitle {
  /* font-family: 'Fugaz One', cursive; */
  color: white;
  /* position: absolute; */
  font-size: 1.8rem;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.p2-date {
  color: rgb(209, 209, 209);
  font-size: 1rem;
}

.p2-img-wrapper {
  margin-bottom: 10px;
  width: 100%;
  max-height: 40vh;
  /* position: absolute; */
  box-shadow: 0px 0px 20px black;
  margin-bottom: 0px;
}

.p2-img-btn {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 192, 203, 0);
  border-style: none;
  padding: 10px;
  cursor: pointer;
  color: white;
}

.p2-img-btn.left {
  left: 0px;
  padding-right: 20px;
}

.p2-img-btn.right {
  right: 0px;
  padding-left: 20px;
}

.p2-img-btn > div {
  position: relative;
}

.p2-img-btn .bi-chevron-right,
.p2-img-btn .bi-chevron-left {
  text-shadow: 1px 1px 3px black;
}

.p2-img-btn .bi-chevron-left.bottom,
.p2-img-btn .bi-chevron-right.bottom {
  position: absolute;
  color: rgb(255, 234, 0);
  top: 3px;
  left: 1px;
}

.p2-img {
  object-fit: cover;
  width: 100%;
}

.p2-img.hidden {
  display: none;
}

.p2-desc {
  /* width: 30%; */
  /* height: 41vh; */
  background-color: rgb(40, 40, 40);
  padding: 15px;
  box-shadow: 0px 0px 20px black;
}

.p2-group-shown .p2-desc {
  z-index: 100;
}

.p2-group-hide .p2-desc {
  z-index: -100;
  position: absolute;
}

.p2-par {
  color: rgb(199, 199, 199);
}

.p2-text {
  animation: 1s ease-in-out 0s subtitleSlideIn forwards;
  transition: opacity 1s ease-in-out;
}

.p2-group-hide .p2-text {
  animation: 1s ease-in-out 0s subtitleSlideOut forwards;
}

.p2-subtitle {
  /* left: 20%; */
  /* top: 14%; */
  text-shadow: 3px 3px 0px #00ffff9a;
  /* transition: transform 1s ease-in-out; */
}

@keyframes subtitleSlideIn {
  50% {
    transform: scale(0.01);
  }
}

@keyframes subtitleSlideOut {
  50% {
    transform: scale(0.01);
  }
}

.p2-img-wrapper {
  /* top: 25%; */
  /* left: 20%; */
  transform-style: preserve-3d;
  animation: 1s ease-in-out 0s imgSlideIn forwards;
}

.p2-group-hide .p2-img-wrapper {
  animation: 1s ease-in-out 0s imgSlideOut forwards;
}

@keyframes imgSlideOut {
  50% { 
    transform: translate(-70%, 10%) rotateY(90deg); 
    font-size: 2rem;
    /* z-index: -1; */
  }
  100% { 
    transform: translate(0%, 20%) rotateY(180deg); 
    font-size: 1.5rem;
  }
}

@keyframes imgSlideIn {
  0% {
    transform: translate(0%, -20%) rotateY(-180deg);
    font-size: 1.5rem;  
    /* z-index: -1; */
    opacity: 0;
  }
  50% {
    transform: translate(70%, -10%) rotateY(-90deg);
    font-size: 2rem;
    opacity: 0.5
  }
}

.p2-desc {
  /* top: 15%; */
  /* left: 57%; */
  animation: 1s ease-in-out 0s descSlideIn forwards;
}

.p2-group-hide .p2-desc {
  animation: 1s ease-in-out 0s descSlideOut forwards;
}

@keyframes descSlideOut {
  100% {
    transform: translateY(-500%)
  }
}

@keyframes descSlideIn {
  0% {
    transform: translateY(500%)
  }
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .p2-page {
    display: block;
  }

  .p2-title {
    color: white;
    font-size: 80px;
    letter-spacing: 10px;
    padding-top: 0px;
    padding-left: 50px;
    text-shadow: 3px 3px 0px rgba(0, 255, 255, 0.602);
    position: absolute;
  }
  
  .p2-canvas {
    height: 93vh;
    width: 100vw;
    /* border-style: solid; */
    border-color: white;
    position: relative;
  }
  
  .p2-left-btn, .p2-right-btn {
    height: 70vh;
    width: 200px;
    /* background-color: rgba(0, 128, 0, 0.353); */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    padding-top: 10%;
  }
  
  .p2-left-btn:hover *, .p2-right-btn:hover * {
    font-size: 2.8rem;
  }
  
  .p2-left-btn:active *, .p2-right-btn:active * {
    color: gray;
  }
  
  .p2-left-btn {
    left: 0px;
  }
  
  .p2-right-btn {
    right: 0px;
  }
  
  .p2-btn-icon {
    font-size: 2rem;
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .p2-group {
    height: 100%;
    width: 100%;
    transition: opacity 2s ease-in-out;
    position: absolute;
    display: block;
  }
  
  .p2-group-hide {
    opacity: 0;
    position: block;
    z-index: 0;
  }
  
  .p2-text {
    margin-bottom: 15px;
    padding-left: 0px;
  }
  
  .p2-subtitle {
    color: white;
    /* position: absolute; */
    font-size: 2.5rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  
  .p2-date {
    color: rgb(209, 209, 209);
    font-size: 1rem;
  }
  
  .p2-img-wrapper {
    margin-bottom: 10px;
    max-height: 50%;
    border-style: solid;
    /* position: absolute; */
    border-radius: 10px;
    box-shadow: 0px 0px 20px black;
    overflow: hidden;
  }

  .p2-img-btn .bi-chevron-right,
  .p2-img-btn .bi-chevron-left {
    text-shadow: 1px 1px 3px black;
    font-size: 20px;
    transition: font-size 0.2s ease-in-out;
  }

  .p2-img-btn:hover .bi-chevron-right,
  .p2-img-btn:hover .bi-chevron-left {
    font-size: 25px;
  }

  .p2-img-btn:active .bi-chevron-right,
  .p2-img-btn:active .bi-chevron-left {
    text-shadow: 0px 0px 0px black;
  }

  
  .p2-desc {
    position: absolute;
    width: 30%;
    height: auto;
    max-height: 65%;
    background-color: rgb(40, 40, 40);
    border-radius: 20px;
    padding: 15px;
    box-shadow: 0px 0px 20px black;
    overflow: scroll;
  }
  
  .p2-par {
    color: rgb(199, 199, 199);
  }
  
  .p2-col1 {
    position: absolute;
    left: 20%;
    top: 14%;
    width: 35%;
  }
  
  .p2-text {
    animation: 2s ease-in-out 0s subtitleSlideIn forwards;
    transition: opacity 1s ease-in-out;
  }
  
  .p2-group-hide .p2-text {
    animation: 2s ease-in-out 0s subtitleSlideOut forwards;
  }
  
  .p2-subtitle {
    /* left: 20%; */
    /* top: 14%; */
    /* transition: transform 1s ease-in-out; */
  }
  
  @keyframes subtitleSlideIn {
    50% {
      transform: scale(0.01);
    }
  }
  
  @keyframes subtitleSlideOut {
    50% {
      transform: scale(0.01);
    }
  }
  
  .p2-img-wrapper {
    /* top: 25%; */
    /* left: 20%; */
    transform-style: preserve-3d;
    animation: 2s ease-in-out 0s imgSlideIn forwards;
  }
  
  .p2-group-hide .p2-img-wrapper {
    animation: 2s ease-in-out 0s imgSlideOut forwards;
  }
  
  @keyframes imgSlideOut {
    50% { 
      transform: translate(-70%, 10%) rotateY(90deg); 
      font-size: 2rem;
      z-index: -1;
    }
    100% { 
      transform: translate(0%, 20%) rotateY(180deg); 
      font-size: 1.5rem;
    }
  }
  
  @keyframes imgSlideIn {
    0% {
      transform: translate(0%, -20%) rotateY(-180deg);
      font-size: 1.5rem;  
      z-index: -1;
      opacity: 0;
    }
    50% {
      transform: translate(70%, -10%) rotateY(-90deg);
      font-size: 2rem;
      opacity: 0.5
    }
  }
  
  .p2-desc {
    top: 15%;
    left: 57%;
    animation: 2s ease-in-out 0s descSlideIn forwards;
  }
  
  .p2-group-hide .p2-desc {
    animation: 2s ease-in-out 0s descSlideOut forwards;
  }
  
  @keyframes descSlideOut {
    100% {
      transform: translateY(-500%)
    }
  }
  
  @keyframes descSlideIn {
    0% {
      transform: translateY(500%)
    }
  }
}


@media only screen and (min-width: 1200px) {
}

.cs-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

@keyframes titleSlide {
    0% { transform: translateY(-50px) }
}

.cs-title {
    color: white;
    font-size: 3em;
    margin-bottom: 20px;
    text-shadow: 2px 3px 8px black;
    box-shadow: 2px 3px 8px black;
    padding: 20px;
    border-radius: 20px;
    animation: 2s ease 0s titleSlide forwards;
}

.cs-phrase {
    color: white;
    font-size: 3rem;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.cs-phrase-word {
    display: flex;
}

.cs-phrase-letter {
    position: relative;
    text-shadow: 2px 2px 5px black;
}

@keyframes shake {
    0% { transform: translateX(5px)}
    5% { transform: translateX(-5px)}
    10% { transform: translate(5px, 5px)}
    15% { transform: translateX(-5px)}
    20% { transform: translate(5px, -5px)}
    25% { transform: translateX(-5px)}
    30% { transform: translateX(5px)}
    35% { transform: translateX(-5px)}
    40% { transform: translateX(5px)}
    45% { transform: translate(-5px, -5px)}
    50% { transform: translateX(5px)}
    55% { transform: translate(-5px, 5px)}
    60% { transform: translateX(5px)}
    65% { transform: translateX(-5px)}
    70% { transform: translateX(5px)}
    75% { transform: translateX(-5px)}
    80% { transform: translateX(5px)}
}

@keyframes csTitle1 {
    0% { transform: translate(-40px, 80px) rotateZ(360deg) }
    50% { transform: translate(-50px, 40px) }
}
@keyframes csTitle2 {
    0% { transform: translate(-45px, -20px) }
}
@keyframes csTitle3 {
    0% { transform: translate(0px, 50px) }
    50% { transform: translate(0px, -50px)}
}
@keyframes csTitle4 {
    0% { transform: translate(-30px, 90px) rotateZ(400deg)}
}
@keyframes csTitle5 {
    0% { transform: translate(-40px, -30px) rotateZ(-90deg)}
}
@keyframes csTitle6 {
    0% { transform: translate(-20px, 0px)}
}

@keyframes csTitle7 {
    0% { transform: translate(20px, 0px)}
}
@keyframes csTitle8 {
    0% { transform: translate(40px, -70px) rotate(360deg)}
    50% { transform: translate(0px, 30px) }
}
@keyframes csTitle9 {
    0% { transform: translate(20px, 80px) rotate(180deg)}
}
@keyframes csTitle10 {
    0% { transform: translate(10px, 90px)}
    50% { transform: translate(40px, 30px) rotate(45deg)}
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .cs-title {
        font-size: 5em;
    }
    .cs-phrase {
        font-size: 4rem;
        grid-gap: 20px;
        gap: 20px;
    }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.m-footer {
    position: fixed;
    bottom: 0px;
    width: 100vw;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.c-footer {
    position: fixed;
    bottom: 0px;
    width: 100vw;
}

.ct-page {
    color: white;
    background-image: linear-gradient(rgb(94, 94, 94), rgb(31, 31, 31));
    height: 100vh;
}

.ct-hero-img {
    width: 100vw;
    height: 150px;
    object-fit: cover;
    box-shadow: 0px 5px 5px black;
    filter: blur(3px);
    object-position: 50% 0%;
}

.ct-avatar-container {
    height: 75px;
    display: flex;
    justify-content: center;
}

.ct-avatar-wrapper, .ct-avatar {
    width: 150px; height: 150px;
    /* border-radius: 50%; */
}

.ct-avatar-wrapper {
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0px 5px 8px rgb(0, 0, 0);
    margin-bottom: 10px;
    position: relative;
    top: -75px;
}

.ct-avatar {
    width: 100%; height: 100%;
    object-fit: cover;
    object-position: -0px -0px;
}

.ct-content-wrapper {
    padding: 15px;
}

.ct-link {
    color: rgb(0, 247, 255);
    font-weight: bold;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .ct-page {
    }

    .ct-hero-img {
        height: 200px;
        object-position: 50% 0%;
    }

    .ct-content-wrapper {
        display: flex;
        justify-content: center;
    }
    
    .ct-content {
        width: 400px;
    }
}


@media only screen and (min-width: 992px) {
    .ct-hero-img {
        object-position: 0px -200px;
    }
}


@media only screen and (min-width: 1200px) {
    .ct-hero-img {
        height: 300px;
        object-position: 0px -250px;
    }
}

.error-page-container {
  height: 130vh;
  width: 100vw;
  margin: 0px;
  background: linear-gradient(90deg, rgba(47,54,64,1) 23%, rgba(24,27,32,1) 100%);
}

.moon {
  background: linear-gradient(90deg, rgba(208,208,208,1) 48%, rgba(145,145,145,1) 100%);
  position: absolute;
  top: -100px;
  left: -300px;
  width: 900px;
  height: 900px;
  content: '';
  border-radius: 100%;
  box-shadow: 0px 0px 30px -4px rgba(0,0,0,0.5);
}

.moon__crater {
  position: absolute;
  content: '';
  border-radius: 100%;
  background: linear-gradient(90deg, rgba(122,122,122,1) 38%, rgba(195,195,195,1) 100%);
  opacity: 0.6;
}

.moon__crater1 {
  top: 250px;
  left: 500px;
  width: 60px;
  height: 180px;
}

.moon__crater2 {
  top: 650px;
  left: 340px;
  width: 40px;
  height: 80px;
  transform: rotate(55deg);
}

.moon__crater3 {
  top: -20px;
  left: 40px;
  width: 65px;
  height: 120px;
  transform: rotate(250deg);
}

.star {
  background: grey;
  position: absolute;
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 100%;
  transform: rotate(250deg);
  opacity: 0.4;
  animation-name: shimmer;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes shimmer {
  from {opacity: 0;}
  to {opacity: 0.7;}
}

.star1 {
  top: 40%;
  left: 50%;
  animation-delay: 1s;
}

.star2 {
  top: 60%;
  left: 90%;
  animation-delay: 3s;
}

.star3 {
  top: 10%;
  left: 70%;
  animation-delay: 2s;
}

.star4 {
  top: 90%;
  left: 40%;
}

.star5 {
  top: 20%;
  left: 30%;
  animation-delay: 0.5s;
}

.error {
  position: absolute;
  left: 100px;
  top: 400px;
  transform: translateY(-60%);
  font-family: 'Righteous', sans-serif;
  color: #363e49;
}

.error__title {
  font-size: 10em;
}

.error__subtitle {
  font-size: 2em;
}

.error__description {
  opacity: 0.5;
  max-width: 400px;
}

.error__button {
  min-width: 7em;
  margin-top: 3em;
  margin-right: 0.5em;
  padding: 0.5em 2em;
  outline: none;
  border: 2px solid #2f3640;
  background-color: transparent;
  border-radius: 8em;
  color: #576375;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 0.75em;
  font-family: 'Righteous', sans-serif;
}

.error__button:hover {
  color: #21252c;
}

.error__button--active {
  background-color: #e67e22;
  border: 2px solid #e67e22;
  color: white;
}

.error__button--active:hover {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
  color: white;
}

.astronaut {
  position: absolute;
  width: 185px;
  height: 300px;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
}

.astronaut__head {
  background-color: white;
  position: absolute;
  top: 60px;
  left: 60px;
  width: 60px;
  height: 60px;
  content: '';
  border-radius: 2em;
}

.astronaut__head-visor-flare1 {
  background-color: #7f8fa6;
  position: absolute;
  top: 28px;
  left: 40px;
  width: 10px;
  height: 10px;
  content: '';
  border-radius: 2em;
  opacity: 0.5;
}

.astronaut__head-visor-flare2 {
  background-color: #718093;
  position: absolute;
  top: 40px;
  left: 38px;
  width: 5px;
  height: 5px;
  content: '';
  border-radius: 2em;
  opacity: 0.3;
}

.astronaut__backpack {
  background-color: #bfbfbf;
  position: absolute;
  top: 90px;
  left: 47px;
  width: 86px;
  height: 90px;
  content: '';
  border-radius: 8px;
}

.astronaut__body {
  background-color: #e6e6e6;
  position: absolute;
  top: 115px;
  left: 55px;
  width: 70px;
  height: 80px;
  content: '';
  border-radius: 8px;
}

.astronaut__body__chest {
  background-color: #d9d9d9;
  position: absolute;
  top: 140px;
  left: 68px;
  width: 45px;
  height: 25px;
  content: '';
  border-radius: 6px;
}

.astronaut__arm-left1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 127px;
  left: 9px;
  width: 65px;
  height: 20px;
  content: '';
  border-radius: 8px;
  transform: rotate(-30deg);
}

.astronaut__arm-left2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 102px;
  left: 7px;
  width: 20px;
  height: 45px;
  content: '';
  border-radius: 8px;
  transform: rotate(-12deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}

.astronaut__arm-right1 {
  background-color: #e6e6e6;
  position: absolute;
  top: 113px;
  left: 100px;
  width: 65px;
  height: 20px;
  content: '';
  border-radius: 8px;
  transform: rotate(-10deg);
}

.astronaut__arm-right2 {
  background-color: #e6e6e6;
  position: absolute;
  top: 78px;
  left: 141px;
  width: 20px;
  height: 45px;
  content: '';
  border-radius: 8px;
  transform: rotate(-10deg);
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
}

.astronaut__arm-thumb-left {
  background-color: #e6e6e6;
  position: absolute;
  top: 110px;
  left: 21px;
  width: 10px;
  height: 6px;
  content: '';
  border-radius: 8em;
  transform: rotate(-35deg);
}

.astronaut__arm-thumb-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 90px;
  left: 133px;
  width: 10px;
  height: 6px;
  content: '';
  border-radius: 8em;
  transform: rotate(20deg);
}

.astronaut__wrist-left {
  background-color: #e67e22;
  position: absolute;
  top: 122px;
  left: 6.5px;
  width: 21px;
  height: 4px;
  content: '';
  border-radius: 8em;
  transform: rotate(-15deg);
}

.astronaut__wrist-right {
  background-color: #e67e22;
  position: absolute;
  top: 98px;
  left: 141px;
  width: 21px;
  height: 4px;
  content: '';
  border-radius: 8em;
  transform: rotate(-10deg);
}

.astronaut__leg-left {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 50px;
  width: 23px;
  height: 75px;
  content: '';
  transform: rotate(10deg);
}

.astronaut__leg-right {
  background-color: #e6e6e6;
  position: absolute;
  top: 188px;
  left: 108px;
  width: 23px;
  height: 75px;
  content: '';
  transform: rotate(-10deg);
}

.astronaut__foot-left {
  background-color: white;
  position: absolute;
  top: 240px;
  left: 43px;
  width: 28px;
  height: 20px;
  content: '';
  transform: rotate(10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
}

.astronaut__foot-right {
  background-color: white;
  position: absolute;
  top: 240px;
  left: 111px;
  width: 28px;
  height: 20px;
  content: '';
  transform: rotate(-10deg);
  border-radius: 3px;
  border-top-left-radius: 8em;
  border-top-right-radius: 8em;
  border-bottom: 4px solid #e67e22;
}



@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&family=Racing+Sans+One&display=swap');

body {
  background-color: rgb(37, 37, 37);
}

.page-title {
  font-weight: bold;
  color: white;
}

.hero-img {
  width: 100vw;
  height: 150px;
  object-fit: cover;
  top: 0px;
  z-index: -1;
}


/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
  .hero-img {
    height: 250px;
  }
}


@media only screen and (min-width: 992px) {
  
}


@media only screen and (min-width: 1200px) {
}

.hp-page {
    width: 100vw;
    position: absolute;
    min-height: 100vh;
}

.hp-hero {
    display: none;
}

.hp-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 50px;
}

.hp-avatar-wrapper, .hp-avatar {
    width: 150px; height: 150px;
    /* border-radius: 50%; */
}

.hp-avatar-wrapper {
    overflow: hidden;
    width: 150px; height: 150px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px rgb(0, 0, 0);
    margin-bottom: 10px;
}

.hp-avatar {
    width: 200%; height: 200%;
    object-fit: cover;
    object-position: -55px 90%;
}

.hp-identity {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.hp-identity-item {
    font-size: 22px;
    color: rgb(219, 219, 219);
    text-align: center;
    margin-right: 2px;
    width: 100%;
}

.hp-tagline {
    padding: 20px;
    padding-bottom: 0px;
    text-align: center;
    color: white;
}

.hp-tech-stack {
    color: rgb(154, 255, 253);
    text-align: center;
    display: flex;
    gap: 15px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: pink; */
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}

.hp-tech-stack-item {
    /* background-color: red; */
    margin-bottom: 0px;
    margin-top: 0px;
    color: rgb(154, 255, 253);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.hp-tech-stack-item:hover {
    color: rgb(255, 255, 255);
    transform: scale(1.1);
    text-decoration: none;
}

.hp-link-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.hp-link-btn {
    background-color: rgba(255, 255, 255, 0);
    border-style: none;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 30px;
    color: rgb(255, 255, 255);
}

.hp-link-btn:hover {
    background-color: rgb(240, 240, 240);
    color: black;
}

.hp-link-btn-text {
    margin-left: 12px;
}


/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .hp-hero {
        object-position: 50% 0%;
        display: block;
    }

    .hp-content {
        width: 600px;
        margin-top: 0px;
        position: relative;
        top: -75px;
    }

    .hp-tech-stack {
        padding-left: 100px;
        padding-right: 100px;
    }
}


@media only screen and (min-width: 992px) {
    .hp-hero {
        object-position: 50% 10%;
    }
}


@media only screen and (min-width: 1200px) {
}

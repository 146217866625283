.bg-grid-container {
    display: none;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
    .bg-main-container {
        position: relative;
        /* z-index: -1; */
    }
    
    .bg-grid-container {
        display: block;
        height: 0px;
        position: relative;
    }

    .bg-grid-screen {
        position: relative;
        top: 0px;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.815);
        /* display: none; */
    }

    .bg-grid {
        display: grid;
        height: 100vh;
        position: relative;
        top: 0px;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.527);
        grid-template-columns: repeat(10fr);
        grid-template-rows: repeat(6fr);
        grid-auto-flow: column;
        column-gap: 3px;
        row-gap: 3px;
        z-index: -4;
    }

    .item {
        /* width: 100%; */
        /* height: 100%; */
        position: relative;
        z-index: -3;
        background-color: black;
        /* background-color: pink; */
    }
    .item-1 {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
    .item-2 {
        grid-column: 1 / 4;
        grid-row: 2 / 4;
    }
    .item-3 {
        grid-column: 1 / 3;
        grid-row: 4 / 6;
    }
    .item-4 {
        grid-column: 1 / 3;
        grid-row: 6 / 7;
    }
    .item-5 {
        grid-column: 3 / 5;
        grid-row: 1 / 2;
    }
    .item-6 {
        grid-column: 4 / 5;
        grid-row: 2 / 3;
    }
    .item-7 {
        grid-column: 3 / 4;
        grid-row: 4 / 6;
    }
    .item-8 {
        grid-column: 3 / 5;
        grid-row: 6 / 7;
    }
    .item-9 {
        grid-column: 5 / 8;
        grid-row: 1 / 3;
    }
    .item-10 {
        grid-column: 4 / 7;
        grid-row: 3 / 6;
    }
    .item-11 {
        grid-column: 5 / 7;
        grid-row: 6 / 7;
    }
    .item-12 {
        grid-column: 8 / 9;
        grid-row: 1 / 3;
    }
    .item-13 {
        grid-column: 7 / 9;
        grid-row: 3 / 5;
    }
    .item-14 {
        grid-column: 7 / 9;
        grid-row: 5 / 7;
    }
    .item-15 {
        grid-column: 9 / 11;
        grid-row: 1 / 2;
    }
    .item-16 {
        grid-column: 9 / 11;
        grid-row: 2 / 4;
    }
    .item-17 {
        grid-column: 9 / 11;
        grid-row: 4 / 6;
    }
    .item-18 {
        grid-column: 9 / 11;
        grid-row: 6 / 7;
    }

    /* Card flipping */
    /* flip effect from https://www.youtube.com/watch?v=OV8MVmtgmoY */
    .bg-card {
        width: 100%;
        height: 100%;
        position: absolute;
        transform-style: preserve-3d;
        -webkit-transition: transform 1.5s ease;
        -o-transition: transform 1.5s ease;
        transition: transform 1.5s ease;
    }
    .bg-card-flipped {
        transform: rotateY(180deg);
    }
    .bg-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        /* border-style: solid; */
        font-size: 30px;
        color: white;
        font-weight: bold;
    }
    .bg-card-front {
        -webkit-backface-visibility: hidden;
        /* backface-visibility: hidden; */
        /* background-color: rgb(117, 211, 255); */
    }
    .bg-card-back {
        -webkit-backface-visibility: hidden;
        /* backface-visibility: hidden; */
        transform: rotateY(180deg);
        /* background-color: rgb(85, 85, 85); */
    }
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

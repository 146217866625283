.p2-page {
  width: 100vw;
  /* height: 100vh; */
  /* background-image: linear-gradient(rgb(52, 58, 64), rgb(14, 14, 14)); */
  /* background-color: pink; */
  z-index: -10;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

.p2-title {
  /* font-family: 'Fugaz One', cursive; */
  color: white;
  font-size: 50px;
  letter-spacing: 5px;
  text-shadow: 3px 3px 0px #00ffff9a;
  padding-left: 15px;
  padding-top: 10px;
  /* background-color: purple; */
}

.p2-canvas {
  width: 100vw;
  /* border-style: solid; */
  border-color: white;
  /* position: relative; */
  position: relative;
  /* background-color: yellow; */
  flex-grow: 1;
}

.p2-left-btn, .p2-right-btn {
  display: none;
}

.p2-group {
  /* height: 100%; */
  width: 100%;
  transition: opacity 1s ease-in-out;
  z-index: 2;
  position: relative;
  /* pointer-events: none; */
  /* display: flex; */
  /* flex-direction: column; */
}

.p2-group-hide {
  opacity: 0;
  top: 0px;
  z-index: 1;
  position: absolute;
}

.p2-col1 {
  width: 100%;
}

.p2-text {
  margin-bottom: 15px;
  padding-left: 15px;
}

.p2-subtitle {
  /* font-family: 'Fugaz One', cursive; */
  color: white;
  /* position: absolute; */
  font-size: 1.8rem;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.p2-date {
  color: rgb(209, 209, 209);
  font-size: 1rem;
}

.p2-img-wrapper {
  margin-bottom: 10px;
  width: 100%;
  max-height: 40vh;
  /* position: absolute; */
  box-shadow: 0px 0px 20px black;
  margin-bottom: 0px;
}

.p2-img-btn {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(255, 192, 203, 0);
  border-style: none;
  padding: 10px;
  cursor: pointer;
  color: white;
}

.p2-img-btn.left {
  left: 0px;
  padding-right: 20px;
}

.p2-img-btn.right {
  right: 0px;
  padding-left: 20px;
}

.p2-img-btn > div {
  position: relative;
}

.p2-img-btn .bi-chevron-right,
.p2-img-btn .bi-chevron-left {
  text-shadow: 1px 1px 3px black;
}

.p2-img-btn .bi-chevron-left.bottom,
.p2-img-btn .bi-chevron-right.bottom {
  position: absolute;
  color: rgb(255, 234, 0);
  top: 3px;
  left: 1px;
}

.p2-img {
  object-fit: cover;
  width: 100%;
}

.p2-img.hidden {
  display: none;
}

.p2-desc {
  /* width: 30%; */
  /* height: 41vh; */
  background-color: rgb(40, 40, 40);
  padding: 15px;
  box-shadow: 0px 0px 20px black;
}

.p2-group-shown .p2-desc {
  z-index: 100;
}

.p2-group-hide .p2-desc {
  z-index: -100;
  position: absolute;
}

.p2-par {
  color: rgb(199, 199, 199);
}

.p2-text {
  animation: 1s ease-in-out 0s subtitleSlideIn forwards;
  transition: opacity 1s ease-in-out;
}

.p2-group-hide .p2-text {
  animation: 1s ease-in-out 0s subtitleSlideOut forwards;
}

.p2-subtitle {
  /* left: 20%; */
  /* top: 14%; */
  text-shadow: 3px 3px 0px #00ffff9a;
  /* transition: transform 1s ease-in-out; */
}

@keyframes subtitleSlideIn {
  50% {
    transform: scale(0.01);
  }
}

@keyframes subtitleSlideOut {
  50% {
    transform: scale(0.01);
  }
}

.p2-img-wrapper {
  /* top: 25%; */
  /* left: 20%; */
  transform-style: preserve-3d;
  animation: 1s ease-in-out 0s imgSlideIn forwards;
}

.p2-group-hide .p2-img-wrapper {
  animation: 1s ease-in-out 0s imgSlideOut forwards;
}

@keyframes imgSlideOut {
  50% { 
    transform: translate(-70%, 10%) rotateY(90deg); 
    font-size: 2rem;
    /* z-index: -1; */
  }
  100% { 
    transform: translate(0%, 20%) rotateY(180deg); 
    font-size: 1.5rem;
  }
}

@keyframes imgSlideIn {
  0% {
    transform: translate(0%, -20%) rotateY(-180deg);
    font-size: 1.5rem;  
    /* z-index: -1; */
    opacity: 0;
  }
  50% {
    transform: translate(70%, -10%) rotateY(-90deg);
    font-size: 2rem;
    opacity: 0.5
  }
}

.p2-desc {
  /* top: 15%; */
  /* left: 57%; */
  animation: 1s ease-in-out 0s descSlideIn forwards;
}

.p2-group-hide .p2-desc {
  animation: 1s ease-in-out 0s descSlideOut forwards;
}

@keyframes descSlideOut {
  100% {
    transform: translateY(-500%)
  }
}

@keyframes descSlideIn {
  0% {
    transform: translateY(500%)
  }
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .p2-page {
    display: block;
  }

  .p2-title {
    color: white;
    font-size: 80px;
    letter-spacing: 10px;
    padding-top: 0px;
    padding-left: 50px;
    text-shadow: 3px 3px 0px rgba(0, 255, 255, 0.602);
    position: absolute;
  }
  
  .p2-canvas {
    height: 93vh;
    width: 100vw;
    /* border-style: solid; */
    border-color: white;
    position: relative;
  }
  
  .p2-left-btn, .p2-right-btn {
    height: 70vh;
    width: 200px;
    /* background-color: rgba(0, 128, 0, 0.353); */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    padding-top: 10%;
  }
  
  .p2-left-btn:hover *, .p2-right-btn:hover * {
    font-size: 2.8rem;
  }
  
  .p2-left-btn:active *, .p2-right-btn:active * {
    color: gray;
  }
  
  .p2-left-btn {
    left: 0px;
  }
  
  .p2-right-btn {
    right: 0px;
  }
  
  .p2-btn-icon {
    font-size: 2rem;
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .p2-group {
    height: 100%;
    width: 100%;
    transition: opacity 2s ease-in-out;
    position: absolute;
    display: block;
  }
  
  .p2-group-hide {
    opacity: 0;
    position: block;
    z-index: 0;
  }
  
  .p2-text {
    margin-bottom: 15px;
    padding-left: 0px;
  }
  
  .p2-subtitle {
    color: white;
    /* position: absolute; */
    font-size: 2.5rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  
  .p2-date {
    color: rgb(209, 209, 209);
    font-size: 1rem;
  }
  
  .p2-img-wrapper {
    margin-bottom: 10px;
    max-height: 50%;
    border-style: solid;
    /* position: absolute; */
    border-radius: 10px;
    box-shadow: 0px 0px 20px black;
    overflow: hidden;
  }

  .p2-img-btn .bi-chevron-right,
  .p2-img-btn .bi-chevron-left {
    text-shadow: 1px 1px 3px black;
    font-size: 20px;
    transition: font-size 0.2s ease-in-out;
  }

  .p2-img-btn:hover .bi-chevron-right,
  .p2-img-btn:hover .bi-chevron-left {
    font-size: 25px;
  }

  .p2-img-btn:active .bi-chevron-right,
  .p2-img-btn:active .bi-chevron-left {
    text-shadow: 0px 0px 0px black;
  }

  
  .p2-desc {
    position: absolute;
    width: 30%;
    height: auto;
    max-height: 65%;
    background-color: rgb(40, 40, 40);
    border-radius: 20px;
    padding: 15px;
    box-shadow: 0px 0px 20px black;
    overflow: scroll;
  }
  
  .p2-par {
    color: rgb(199, 199, 199);
  }
  
  .p2-col1 {
    position: absolute;
    left: 20%;
    top: 14%;
    width: 35%;
  }
  
  .p2-text {
    animation: 2s ease-in-out 0s subtitleSlideIn forwards;
    transition: opacity 1s ease-in-out;
  }
  
  .p2-group-hide .p2-text {
    animation: 2s ease-in-out 0s subtitleSlideOut forwards;
  }
  
  .p2-subtitle {
    /* left: 20%; */
    /* top: 14%; */
    /* transition: transform 1s ease-in-out; */
  }
  
  @keyframes subtitleSlideIn {
    50% {
      transform: scale(0.01);
    }
  }
  
  @keyframes subtitleSlideOut {
    50% {
      transform: scale(0.01);
    }
  }
  
  .p2-img-wrapper {
    /* top: 25%; */
    /* left: 20%; */
    transform-style: preserve-3d;
    animation: 2s ease-in-out 0s imgSlideIn forwards;
  }
  
  .p2-group-hide .p2-img-wrapper {
    animation: 2s ease-in-out 0s imgSlideOut forwards;
  }
  
  @keyframes imgSlideOut {
    50% { 
      transform: translate(-70%, 10%) rotateY(90deg); 
      font-size: 2rem;
      z-index: -1;
    }
    100% { 
      transform: translate(0%, 20%) rotateY(180deg); 
      font-size: 1.5rem;
    }
  }
  
  @keyframes imgSlideIn {
    0% {
      transform: translate(0%, -20%) rotateY(-180deg);
      font-size: 1.5rem;  
      z-index: -1;
      opacity: 0;
    }
    50% {
      transform: translate(70%, -10%) rotateY(-90deg);
      font-size: 2rem;
      opacity: 0.5
    }
  }
  
  .p2-desc {
    top: 15%;
    left: 57%;
    animation: 2s ease-in-out 0s descSlideIn forwards;
  }
  
  .p2-group-hide .p2-desc {
    animation: 2s ease-in-out 0s descSlideOut forwards;
  }
  
  @keyframes descSlideOut {
    100% {
      transform: translateY(-500%)
    }
  }
  
  @keyframes descSlideIn {
    0% {
      transform: translateY(500%)
    }
  }
}


@media only screen and (min-width: 1200px) {
}

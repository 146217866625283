.sp-container {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 100px;
  /* background-color: rgba(255, 192, 203, 0.466); */
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.sp-body {
  width: 250px;
  height: 60px;
  background-color: rgb(0, 93, 0);
  border-radius: 30px;
  box-shadow: 0px 0px 20px black;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(0, 145, 0);
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  transform: translateX(100vw);
  animation: 5s ease-in-out 1s bodySlide forwards;
}

@keyframes bodySlide {
  15% {
    transform: translateX(0vw);
  }
  80% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}

.sp-slider {
  width: 70px;
  height: 55px;
  border-radius: 30px;
  background-color: rgb(0, 206, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  animation: 2.5s ease-in-out 2s slider forwards;
}

:root {
  --slide-1: -60px;
  --slide-2: -66px;
}

@keyframes slider {
  35% {
    transform: translateX(var(--slide-1));
  }
  40% {
    transform: translateX(var(--slide-1));
  }
  47% {
    transform: translateX(var(--slide-2));
  }
  54% {
    transform: translateX(var(--slide-1));
  }
  61% {
    transform: translateX(var(--slide-2));
  }
  68% {
    transform: translateX(var(--slide-1));
  }
  75% {
    transform: translateX(var(--slide-1));
  }
  100% {
    transform: translateX(0px);
  }
}

.sp-chevron {
  font-size: 25px;
  padding-bottom: 3px;
  padding-right: 3px;
}

.sp-arrows {
  position: absolute;
  left: -50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.sp-moving-chevron {
  font-size: 40px;
  font-weight: bold;
  transform: scaleY(1.5);
  color: rgb(0, 77, 0);
  padding: 0px;
  margin: 0px;
  animation: 1s linear 0s infinite arrows forwards;
}

@keyframes arrows {
  100% {
    transform: translateX(-41px) scaleY(1.5);
  }
}

.sp-text {
  color: rgb(110, 181, 110);
  right: 0px;
  width: 100%;
  vertical-align: center;
  font-size: 18px;
  font-family: 'Fugaz One', cursive;
  padding-left: 12px;
  text-shadow: 0px 0px 15px black;
  position: relative;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 767px) {
}


@media only screen and (min-width: 992px) {
  .sp-container {
    display: none;
  }
}


@media only screen and (min-width: 1200px) {
}

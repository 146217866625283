.footer-container {
    /* border-top-style: solid; */
    border-width: 2px;
    border-color: rgb(221, 221, 221);
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(52, 58, 64);
    position: sticky;
    bottom: 0px;
}

.footer-text, .footer-icon, .footer-link {
    color: rgb(209, 209, 209);
}

.footer-text {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
}

.footer-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-ig-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer-icon {
    margin-right: 5px;
}

.footer-link:hover {
    color: white;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
    .footer-large-only {
        display: none;
    }

    .footer-container {
        justify-content: center;
    }
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}

.navbar-profile-name {
    padding: 10px;
}

.nav-icon {
    margin-right: 2px;
}

.brand-link {
    color: rgb(231, 231, 231);
}

.brand-link:hover {
    color: rgb(192, 192, 192);
    text-decoration: none;
}

.brand-link, .brand-link:hover {
    transition-duration: 0.3s;
}

/* Screen size specs */

@media only screen and (max-width: 576px) {
}


@media only screen and (min-width: 576px) {
}


@media only screen and (min-width: 768px) {
}


@media only screen and (min-width: 992px) {
}


@media only screen and (min-width: 1200px) {
}
